export type TagId = string;
export type ClassId = string;

export type SelectionId = TagId | ClassId;

export type Tag = {
  name: string;
  uuid: TagId;
  shorthand: string;
  owner?: string;
  hasChildren?: boolean;
  hasTimetable?: boolean;
};
export type TagSelection = {
  tags: Tag[];
  selected: Tag | null;
};

export type TClassSimple = {
  start: number;
  duration: number;
  teacher: string;
  subject: string;
  type: string;
  day: string;
  week: number;
};

export type TClass = TClassSimple & {
  uuid: ClassId;
  tagName: string;
};

export type Week<T> = {
  [key in Day]: T[];
};

export type Day = "monday" | "tuesday" | "wednesday" | "thursday" | "friday" | "saturday" | "sunday";

export type Timetable = TimetableSimple<TClass>;

export type TimetableSimple<T extends TClassSimple> = {
  weeks: Week<T>[];
};

export type ShorthandResponse = {
  shorthands: Tag[];
  class?: TClass;
};

export type UserInfo = {
  isOwner: boolean;
};

export type CompareResult = {
  isSame: boolean;
};

export const Days: Day[] = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];
export const JavascriptDays: Day[] = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];

export const EmptyTimetable = {
  weeks: [
    {
      monday: [],
      tuesday: [],
      wednesday: [],
      thursday: [],
      friday: [],
      saturday: [],
      sunday: [],
    },
    {
      monday: [],
      tuesday: [],
      wednesday: [],
      thursday: [],
      friday: [],
      saturday: [],
      sunday: [],
    },
  ],
};

export function getEmptyTimetable<C extends TClassSimple, T extends TimetableSimple<C>>(): T {
  return {
    weeks: [
      {
        monday: [],
        tuesday: [],
        wednesday: [],
        thursday: [],
        friday: [],
        saturday: [],
        sunday: [],
      },
      {
        monday: [],
        tuesday: [],
        wednesday: [],
        thursday: [],
        friday: [],
        saturday: [],
        sunday: [],
      },
    ],
  } as T;
}

import * as React from "react";
import { useEffect, useMemo, useState } from "react";
import { PageProps } from "gatsby";
import { useTranslation } from "react-i18next";
import { useLocalization } from "gatsby-theme-i18n";
import axios from "axios";
import { toast } from "react-hot-toast";
import { Tag } from "../util/enpoints/types";
import Seo from "../components/seo";
import NavBar from "../components/navBar";
import DisplayTimetable from "../components/elements/displayTimetable";
import { getChildrenTags, getShorthands, getTag } from "../util/enpoints";
import StandardToaster from "../components/standardToaster";
import ChildrenNavigator from "../components/public-timetable/children-naviagtor";
import Breadcrumbs from "../components/breadcrumbs";

const PublicTimetable: React.FC<PageProps<never, { tag: Tag; shorthands: Tag[]; tagChildren: Tag[] }>> = ({
  pageContext: { tag: externalTag, shorthands: externalShorthands, tagChildren: externalTagChildren },
  params: { uid },
}) => {
  const tagId = useMemo(() => (uid != null ? uid : externalTag?.uuid), [externalTag?.uuid, uid]);
  const [tag, setTag] = useState<Tag>(externalTag);
  const [shorthands, setShorthands] = useState<Tag[]>(externalShorthands != null ? externalShorthands : []);
  const [tagChildren, setTagChildren] = useState<Tag[]>(externalTagChildren != null ? externalTagChildren : []);
  const { t } = useTranslation();
  const { locale } = useLocalization();
  const breadCrumbs = useMemo(() => {
    const newB = shorthands
      .slice(0, shorthands.length - 1)
      .map((item) => ({ text: item.name, url: `/timetable/${item.uuid}` }));
    newB.splice(0, 0, { text: t("home"), url: "/timetable" });
    return newB;
  }, [shorthands, t]);
  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    let cleanup = false;

    async function getUpdatedData(): Promise<void> {
      try {
        const uuid = tagId;
        const resTag = await getTag(uuid, locale, cancelToken.token);
        const resShorthands = await getShorthands(uuid, locale, cancelToken.token);
        const resChildren = await getChildrenTags(uuid, locale, cancelToken.token);
        if (cleanup) return;
        console.log("new data set");
        setShorthands(resShorthands.data.shorthands);
        setTagChildren(resChildren.data);
        setTag(resTag.data);
      } catch (e) {
        if (e instanceof axios.Cancel) {
          console.log(e);
          return;
        }
        if (axios.isAxiosError(e)) {
          for (const errMsg of e.response.data.errors) {
            toast.error(errMsg);
          }
        } else {
          throw e;
        }
      }
    }

    getUpdatedData();
    return () => {
      cleanup = true;
      cancelToken.cancel("Only one request can be performed");
    };
  }, [locale, tagId]);

  if (tag == null) {
    return (
      <div className="flex justify-center items-center flex-grow h-screen">
        <StandardToaster />
        <h1 className="text-white text-center text-4xl md:text-5xl lg:text-7xl">{t("loading")}</h1>
      </div>
    );
  }
  return (
    <>
      <Seo title={`${tag.hasChildren ? t("folder") : t("timetable")} ${tag.name}`} />
      <StandardToaster />
      <div className="flex flex-col h-full">
        <NavBar title={`${tag.hasChildren ? t("folder") : t("timetable")} ${tag.name}`} backButton={false} />
        <div className="flex justify-between items-center m-2">
          <Breadcrumbs history={breadCrumbs} />
        </div>
        {tagChildren.length === 0 ? (
          <DisplayTimetable preview={false} selectionUIDs={[tagId]} />
        ) : (
          <ChildrenNavigator tagChildren={tagChildren} />
        )}
      </div>
    </>
  );
};

export default PublicTimetable;
